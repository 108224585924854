import logo from './logo.svg';
import './App.css';
import Header from './header';
import Home from './home';
import Skills from './skills';
import Projects from './projects';
import Contact from './contact';

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Skills />
      <Projects />
      <Contact/>
    </div>
  );
}

export default App;
