import './contact.css';
import Profile from './images/profile.png';
import Linkedin from "./images/Linkedin2.png";
import Github from "./images/Github2.png";
import Resume from "./images/resume2.png";
import Phone from "./images/contact2.png";

function Contact() {
    return (
        <div className="contact">
            <h3>GET IN TOUCH</h3>
            <div className="bottom">
                <div className="">
                    <img src={Profile} className="contact-image"/>
                    <div>
                        <a href="https://www.linkedin.com/in/grace-wambui-64a5ba201/" target="blank" className="socials">
                                <img src={Linkedin}/>
                        </a>
                        <a href="https://github.com/wambui-g/" target="blank" className="socials">
                                <img src={Github}/>
                        </a>
                        <a href="https://drive.google.com/file/d/1lo2CYHzA6GF9Q6kFgShy8ThJFCOSBNXe/view?usp=drive_link" target="blank" className="socials">
                                <img src={Resume}/>
                        </a>
                        <a href="https://www.linkedin.com/in/grace-wambui-64a5ba201/" target="blank" className="socials">
                                <img src={Phone}/>
                        </a> 
                    </div>
                </div>
                
            </div>

            <div>
                <form></form>
            </div>
        </div>
    );
}

export default Contact;