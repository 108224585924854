import './projects.css';

function Projects() {
    return (
        <div className="projects">
            <h3>PROJECTS WORKED ON</h3>
        </div>
    );
}

export default Projects;