import './header.css';

function Header() {
    return (
        <div className="header">
            <a></a>
            <h2>Skills</h2>
            <h2>Projects</h2>
            <h2>Contacts</h2>
        </div>
    );
}

export default Header;