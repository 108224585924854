import './AnimatedLine.css';

function AnimatedLine() {
    return (
        <div className="line-container">
            <svg width="180" height="180" viewBox="-90 -90 180 180">
                <path
                d="M-90,0 L0,90 L90,0 L0,-90 Z"
                className="line"
        />
      </svg>
        </div>
    );
}

export default AnimatedLine;