import AnimatedLine from './AnimatedLine';
import './home.css';
import profile from "./images/profile.png";
import Linkedin from "./images/Linkedin.png";
import Github from "./images/Github.png";
import Resume from "./images/resume.png";
import Contact from "./images/contact.png";

function Home() {
    return (
        <div className="home">
            <div>
                <AnimatedLine/>
                <img className="home-image" src={profile}/>
            </div>
            <p>"Crafting Innovative Solutions with Creativity and Code..."</p>
            <div className="home-text">
                <h3>Hi, I'm WAMBUI </h3>
                <h3>A Software Engineer</h3>
                <p>
                    Hello! I'm Grace Wambui, a passionate Full Stack Engineer with a background in Mathematics and Computer Science.
                    I recently completed an intensive 12-month software engineering course with ALX, which honed my skills and fueled my enthusiasm for building innovative solutions.
                    I firmly believe that "your mind is only as big as what you are exposed to," and this drives my relentless pursuit of new opportunities and challenges.
                    I thrive in environments where I can continually learn and grow, applying my expertise to create impactful projects.
                </p>
            </div>

            <div>
                <a href="https://www.linkedin.com/in/grace-wambui-64a5ba201/" target="blank" className="socials">
                    <img src={Linkedin}/>
                </a>
                <a href="https://github.com/wambui-g/" target="blank" className="socials">
                    <img src={Github}/>
                </a>
                <a href="https://drive.google.com/file/d/1lo2CYHzA6GF9Q6kFgShy8ThJFCOSBNXe/view?usp=drive_link" target="blank" className="socials">
                    <img src={Resume}/>
                </a>
                <a href="https://www.linkedin.com/in/grace-wambui-64a5ba201/" target="blank" className="socials">
                    <img src={Contact}/>
                </a>
            </div>
        </div>
    );
}

export default Home;