import './skills.css';
import Html from './images/Html.png';
import Css from './images/CSS.png';
import JS from './images/Javascript.png';
import MySql from './images/Mysql.png';
import Python from './images/React.png';
import Flask from './images/Flask.png';
import Django from './images/Django.png';
import Rct from './images/React.png';

function Skills() {
    return (
        <div className="skills">
            <h3>TECHNICAL SKILLS</h3>
            <div className="row">
                <div className="col col-6 col-lg-3">
                    <img src={Html} />
                    <p>HTML</p>
                </div>
                <div className="col col-6 col-lg-3">
                    <img src={Css} />
                    <p>CSS</p>
                </div>
                <div className="col col-6 col-lg-3">
                    <img src={JS} />
                    <p>Java Script</p>
                </div>
                <div className="col col-6 col-lg-3">
                    <img src={MySql} />
                    <p>MySql</p>
                </div>
            </div>
            <div className="row">
                <div className="col col-6 col-lg-3">
                    <img src={Python} />
                    <p>Python</p>
                </div>
                <div className="col col-6 col-lg-3">
                    <img src={Flask} />
                    <p>Flask</p>
                </div>
                <div className="col col-6 col-lg-3">
                    <img src={Django} />
                    <p>Django</p>
                </div>
                <div className="col col-6 col-lg-3">
                    <img src={Rct} />
                    <p>React</p>
                </div>
            </div>
        </div>
    );
}

export default Skills;